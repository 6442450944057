.events-list-filter-bar-dates-dropdown {
  .header {
    padding-bottom: 15px;
  }

  .dropdown {
    padding: 10px 20px;
  }

  .flex-container {
    align-items: center;
    column-gap: 15px;

    .fa-arrow-right {
      margin-top: 10px;
    }

    input {
      color: #666;
    }

  }
  
  .validation-button {
    text-align: right;
  }
}
