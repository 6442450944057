.events-list-filter-bar-country-dropdown {
  .dropdown {   
    padding: 10px 0;
    .country {
      background-color: #0000;
      border: 0;
      clear: both;
      color: #666;
      display: block;
      font-weight: 400;
      outline-width: 0;
      padding: .25rem 1.5rem;
      text-align: inherit;
      white-space: nowrap;
      width: 100%;
      cursor: pointer;
      
      &:hover {
        background-color: #f8f9fa;
        text-decoration: none;
      }
    }
  }
}
