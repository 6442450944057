.marketplace-classified-actions-legals {
  .artp-modal-content {
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 0;

    .common-Spinner {
      margin: 40px 0;

      .fa-spin {
        font-size: 5em;
      }
    }
  }
}
