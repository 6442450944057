.lots-thumbnails-carousel {
  width: 100%;
  
  .slick-dots {
    bottom: -15px;
  }
  
  .slick-slide {
    display: flex !important;
    justify-content: center;
    
    div:first-of-type {
      align-content: center;
      height: 100%;
    }

    &.slick-active {
      z-index: 10;
    }
  }
}
