.actions-message-modale-form {
  .artp-modal-content {
    padding: 0;
    width: 100%;

    .modal-body {
      display: flex;
      flex-flow: column;
      padding: 0;

      .component-html-editor-input {
        border: 0;
        border-bottom: 1px solid #e5e5e5;
      }
    }

    .modal-footer {
      border-top: 0;
      display: flex;
      flex-flow: row;

      .link {
        align-self: center;
        flex: 1 1 0%;
        text-align: left;
      }
    }
  }
}
