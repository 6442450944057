.reports-list {
  h1 {
    margin-bottom: 20px;
    text-align: center;
  }

  .report {
    margin-top: 40px;
    display: flex;
    column-gap: 30px;

    .report-img {
      padding: 15px;
      display: flex;
    }

    .report-data {
      flex: 1;
      display: flex;
      flex-direction: column;

      .report-title {
        font-size: 22px;
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;

        .htl {
          color: #337ab7;
          font-size: 1.2em;
          font-weight: 300;
        }
      }
    }
  }

  .artp-ui-pagination {
    margin-top: 40px;
    justify-content: center;
  }


  .back-to-top-container {
    bottom: 25px;
    margin-bottom: 10px;
    position: sticky;
    float: right;
    padding-right: 20px;
    z-index: 20;
  }
}
