@use '../../../../../../../assets/stylesheets/artp_colors';

.no-pict {
  align-items: center;
  border: solid 1px artp_colors.$gray-light;
  border-radius: 5px;
  box-shadow: 2px 2px 5px artp_colors.$gray-eee;
  color: artp_colors.$gray-light;
  display: flex;
  flex-flow: column;
  height: 175px;
  justify-content: space-evenly;
  width: 147px;
  margin: 0 auto;

  > p {
    color: #b7b7b7;
  }
}

.lot-img .no-pict > img {
  max-height: 50px !important;
}
