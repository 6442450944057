.account-lots-lists-add-to-list-button-create {
  span.title {
    padding-left: 5px;
  }

  button {
    height: 100%;
  }

  @media (max-width: 991px) {
    button {
      span.count {
        display: none;
      }
    }
  }
  
  .standalone {
    span.count {
      display: none;
    }
  }
}
