.account-confirm-explain {
  color: rgb(49, 112, 143);
  background-color: rgb(217, 237, 247);
  border-color: rgb(188, 232, 241);
  margin-bottom: 20px;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}
