.lots-show-filters {
  margin: 30px 0;
  display: flex;
  column-gap: 15px;

  .filler {
    flex: 1;
  }

  .artp-btn {
    padding: 5px 10px;
  }

  .common-preferences {
    align-content: center;

    .title {
      font-size: 16px;
    }
  }
}
