@use '../../../../../../assets/stylesheets/artp_colors';

.lots-tile-access-wide {
  a {
    color: #000;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-flow: row;
    font-size: 14px;
    position: relative;
    width: 100%;
    padding-bottom: 10px;

    @media(max-width: 720px) {
      flex-direction: column;

      .lot-img {
        align-self: center;
      }

      .cell-1 {
        text-align: center;

        .lot-title {
          margin: 0 auto;
        }
      }
    }

    .lots-thumbnails-carousel {
      align-content: center;
      
      .slick-slide {
        div:first-of-type {
          display: flex;
        }
      }
    }
  }

  .button-follow {
    display: none;
    position: absolute;
    right: 5px;
    top: 5px;

    button {
      background: none;
      border: 0;
      outline: none;
    }

    @media(max-width: 720px) {
      display: inline;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px artp_colors.$gray-eee;
    color: #000;
    text-decoration: none;
    transition: 0.2s;

    .button-follow {
      display: inline;
    }
  }

  .lot-img {
    display: flex;
    height: 220px;
    justify-content: center;
    margin-right: 15px;
    width: 220px;

    img {
      margin: auto;
      max-height: 210px;
      max-width: 210px;
      text-align: center;
    }
  }

  .cell-1 {
    flex: 1;

    .lot-title {
      font-style: italic;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 210px;
    }

    .lot-artist {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .prices {
      font-size: 1.4rem;
    }
  }

  .cell-2 {
    flex: 1;

    @media(max-width: 720px) {
      display: none;
    }

    .lot-auctioneer {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .lots-tile-links {
      text-align: left;
    }
  }
}

.unfollowed {
  opacity: 0.4;
}
