.breadcrumb {
  background-color: #fff;
  font-size: 12px;

  nav {
    ol {
      list-style-type: none;
      padding-left: 0;
      display: flex;

      li {
        &:after {
          display: inline-block;
          font-size: 12px;
          font-weight: bold;
          color: rgb(204, 204, 204);
          content: "/ ";
          padding: 0 7px;
        }

        &:last-of-type {
          &:after {
            content: ''
          }

          a {
            color: rgb(119, 119, 119);
          }
        }
      }
    }
  }
}
